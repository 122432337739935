
//
// Menth|Year [Spacer] <Back|Today|Forward>]
// [Space] [Month][Day]
// Icon|Text [Spacer] Time
//
// Colors -
// - primary background - white
// - secondary background - lightgray
//    - weekends
// - primary foreground
// - secondary foreground
//	  - weekends
//	  - other-month days

import React, { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { ICommand } from "../AppSchema";
import { TitleBox } from "../components/TitleBox";
import { useDerivedState } from "../hooks/useDerivedState"
import { useSizeQuery } from "../hooks/useSizeObserver";
import { addDays, DAY_IN_MILLIS, ICalendarEvent } from "./ICalendarEvent";

const getFirstVisualMonthDay = (date: Date, firstDayOfWeek: number) => {
	let d = new Date(date.getFullYear(), date.getMonth(), 1);
	while (d.getDay() !== firstDayOfWeek) {
		d = addDays(d, -1);
	}
	return d;
}

// const test = () => {

// 	for (let y = 2000; y < 2050; y++) {
// 		for (let i = 0; i < 12; i++) {
// 			const currentDate = new Date(y, i, 1);
// 			const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth());
// 			const lastDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 1);
// 			const lastDayOfMonthUTC = Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
// 			const firstDayOfWeek = 1; // hardcoded monday for now
// 			const firstDay = getFirstVisualMonthDay(firstDayOfMonth, firstDayOfWeek);
// 			const firstDayUTC = Date.UTC(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate());
		
// 			const weeks = ((((lastDayOfMonth.getTime() - firstDay.getTime()) / DAY_IN_MILLIS) | 0) + 6) / 7 | 0;
// 			const weeks2 = ((((lastDayOfMonthUTC - firstDayUTC) / DAY_IN_MILLIS) | 0) + 6) / 7 | 0;

// 			if (weeks !== weeks2) {
// 				console.log("WEEK ERROR:" + weeks + '  !=  ' + weeks2 + "  " + currentDate.toDateString());
// 			}
// 		}
// 	}
// }

export const MonthCalendar = (props: {
	initialDate?: Date,
	onDateChanged?: (v: Date | ((prev: Date) => Date)) => void,
	onChangeViewMode: (e: HTMLElement) => void,
	nameField?: string,
	rows?: ICalendarEvent[],
	onNewEvent?: (r: any) => void,
	commands: ICommand[],
	onCommand: (cmd: ICommand, e: any) => void,
}) => {
	const [currentDate, setCurrentDate] = useDerivedState(props.initialDate || new Date(), [props.initialDate]);
	const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth());
	const lastDayOfMonthUTC = Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
	const firstDayOfWeek = 1; // hardcoded monday for now
	const firstDay = getFirstVisualMonthDay(firstDayOfMonth, firstDayOfWeek);
	const firstDayUTC = Date.UTC(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate());
	
	const weeks = ((((lastDayOfMonthUTC - firstDayUTC) / DAY_IN_MILLIS) | 0) + 6) / 7 | 0;

	const newArray = (len: number) => {
		let a = new Array(len); for (let i = 0; i < len; ++i) a[i] = 0;
		return a;
	}
	const header = newArray(7).map((x, i) => addDays(firstDay, i).toLocaleString(undefined, { weekday: 'short' }));

	const items = newArray(weeks * 7);
	const now = new Date();

	const title = firstDayOfMonth.toLocaleString(undefined, { month: 'short', year: 'numeric' });

	const onCommand = useCallback((cmd: ICommand, e: any) => {
		const onSetDate = props.onDateChanged || setCurrentDate;
		switch (cmd.name) {
			case "CmdBack": onSetDate(d => new Date(d.getFullYear(), d.getMonth() - 1)); break;
			case "CmdFwd": onSetDate(d => new Date(d.getFullYear(), d.getMonth() + 1)); break;
			default: props.onCommand(cmd, e); break;
		}
	}, [props.onDateChanged, setCurrentDate]);

	const [mainRef, isSmall] = useSizeQuery<HTMLDivElement>(700);


	const onDoubleClick = useCallback((e: React.MouseEvent) => {
		let element = e.target as HTMLElement;
		while (element) {
			const day = element.dataset['calday'];
			if (day) {
				const dd = new Date(day).valueOf();
				const start = new Date(dd + 3600 * 1000 * 9);
				const end = new Date(dd + 3600 * 1000 * 10);
				if (props.onNewEvent)
					props.onNewEvent({ start, end });
				return;
			}
			element = element.parentElement as HTMLElement;	
		}
	},[])

	return (<div className="calendarBody" ref={mainRef}>
		<div key="title" className="calendarTitle"><TitleBox title={title} commands={props.commands} onCommand={onCommand} /></div>
		{header.map((x) => (<div key={"hdr" + x} className="calendarHeaderCell">{x}</div>))}
		{items.map((x, i) => {
			let cellClass = "calendarCell";
			const d = addDays(firstDay, i);
			if (d.getDay() === 0 || d.getDay() === 6) // weekend
				cellClass += " calendarCellWeekend";
			if (d.getMonth() !== firstDayOfMonth.getMonth())
				cellClass += " calendarCellOtherMonth";
			if (d.getMonth() === now.getMonth() && d.getDate() == now.getDate())
				cellClass += " calendarCellToday";
			const hdr = d.getDate().toString() + (d.getDate() === 1 ? " " + (d.toLocaleString(undefined, { month: 'short' })) : "");
			
			let dayItems = [] as { time: string, label: string, url: string, color: string }[];
			if (props.rows) {
				for (const x of props.rows) {
					const s = x.start;
					const e = x.end || s;
					if (s && e) {
						const sd = new Date(s);
						const ed = new Date(e);
						if (ed >= d && sd <= addDays(d, 1)) {
							dayItems.push({
								time: sd < d ? "0:00" : sd.toLocaleString([], { hour: '2-digit', minute: '2-digit' }),
								label: x.name || "Event",
								url: x.url || "#",
								color: x.color || "var(--acccent-color)"
							});
						}
					}
				}
			}
			
			return <div className={cellClass} key={"day"+i} data-calday={d.toISOString()} onDoubleClick={onDoubleClick}>
				<div className="calendarCellHeader">{hdr}</div>
				<div className="calendarCellContent">
					<div className="calendarCellContentBody">
						{dayItems.map(y => (
							<React.Fragment key={y.url}>
								<NavLink className="calendarEventLabel" to={y.url} style={{borderLeft: "3px solid "+y.color}}>{y.label}</NavLink>
								{!isSmall && <NavLink className="calendarEventTime" to={y.url}>{y.time}</NavLink>}
								{isSmall && <div/>}
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
		})}
	</div>)
}